import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["searchInput", "categoryItem", "checkbox", "selectedList"];

  declare searchInputTarget: HTMLInputElement;
  declare categoryItemTargets: HTMLElement[];
  declare checkboxTargets: HTMLInputElement[];
  declare selectedListTarget: HTMLElement;

  connect() {
    console.log("Category search controller connected");
    this.updateSelected();
  }

  filter() {
    const query = this.searchInputTarget.value.toLowerCase();

    this.categoryItemTargets.forEach((item) => {
      const text = item.textContent?.toLowerCase() || "";
      item.style.display = text.includes(query) ? "" : "none";
    });
  }

  updateSelected() {
    this.selectedListTarget.innerHTML = "";

    this.checkboxTargets.forEach((checkbox) => {
      if (checkbox.checked) {
        const categoryName =
          checkbox.closest("label")?.textContent?.trim() || "Unknown";

        const categoryItem = document.createElement("span");
        categoryItem.className = "badge badge-info";
        categoryItem.style.padding = "5px 10px";
        categoryItem.style.borderRadius = "5px";
        categoryItem.style.display = "inline-block";
        categoryItem.style.backgroundColor = "#007bff";
        categoryItem.style.color = "#fff";
        categoryItem.style.fontSize = "14px";
        categoryItem.innerText = categoryName;

        this.selectedListTarget.appendChild(categoryItem);
      }
    });
  }
}
