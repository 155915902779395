// Extend the Window interface to include the currentUser property
declare global {
  interface Window {
    currentUser: {
      role: string;
    };
  }
}

import { Controller } from "@hotwired/stimulus";
import moment from "moment";

export default class extends Controller {
  static targets = [
    "city",
    "locationType",
    "building",
    "flughafenZone",
    "area",
    "finger",
    "level",
    "address",
    "playerName",
    "boxId",
    "aptCode",
    "country",
    "gfCategories",
  ];

  declare cityTarget: HTMLSelectElement;
  declare locationTypeTarget: HTMLSelectElement;
  declare buildingTarget: HTMLSelectElement;
  declare flughafenZoneTarget: HTMLSelectElement;
  declare areaTarget: HTMLSelectElement;
  declare fingerTarget: HTMLSelectElement;
  declare levelTarget: HTMLSelectElement;
  declare addressTarget: HTMLInputElement;
  declare playerNameTarget: HTMLInputElement;
  declare boxIdTarget: HTMLInputElement;
  declare aptCodeTarget: HTMLInputElement;
  declare countryTarget: HTMLSelectElement;
  declare gfCategoriesTarget: HTMLSelectElement;

  declare hasCityTarget: boolean;
  declare hasLocationTypeTarget: boolean;
  declare hasBuildingTarget: boolean;
  declare hasFlughafenZoneTarget: boolean;
  declare hasAreaTarget: boolean;
  declare hasFingerTarget: boolean;
  declare hasLevelTarget: boolean;
  declare hasAddressTarget: boolean;
  declare hasPlayerNameTarget: boolean;
  declare hasBoxIdTarget: boolean;
  declare hasAptCodeTarget: boolean;
  declare hasCountryTarget: boolean;
  declare hasGfCategoriesTarget: boolean;

  connect() {
    console.log("Daisy Dashboard connected");

    if (this.hasCityTarget) {
      this.cityTarget.addEventListener("change", this.filter_change.bind(this));
    } else {
      console.error("city target not found");
    }

    if (this.hasLocationTypeTarget) {
      this.locationTypeTarget.addEventListener(
        "input",
        this.filter_change.bind(this)
      );
    } else {
      console.error("locationType target not found");
    }

    if (this.hasBuildingTarget) {
      this.buildingTarget.addEventListener(
        "change",
        this.filter_change.bind(this)
      );
    } else {
      console.error("building target not found");
    }

    if (this.hasFlughafenZoneTarget) {
      this.flughafenZoneTarget.addEventListener(
        "change",
        this.filter_change.bind(this)
      );
    } else {
      console.error("flughafenZone target not found");
    }

    if (this.hasAreaTarget) {
      this.areaTarget.addEventListener("change", this.filter_change.bind(this));
    } else {
      console.error("area target not found");
    }

    if (this.hasFingerTarget) {
      this.fingerTarget.addEventListener(
        "change",
        this.filter_change.bind(this)
      );
    } else {
      console.error("finger target not found");
    }

    if (this.hasLevelTarget) {
      this.levelTarget.addEventListener(
        "change",
        this.filter_change.bind(this)
      );
    } else {
      console.error("level target not found");
    }

    if (this.hasAddressTarget) {
      this.addressTarget.addEventListener(
        "input",
        this.filter_change.bind(this)
      );
    } else {
      console.error("address target not found");
    }

    if (this.hasPlayerNameTarget) {
      this.playerNameTarget.addEventListener(
        "input",
        this.filter_change.bind(this)
      );
    } else {
      console.error("playerName target not found");
    }

    if (this.hasBoxIdTarget) {
      this.boxIdTarget.addEventListener("input", this.filter_change.bind(this));
    } else {
      console.error("boxId target not found");
    }

    if (this.hasAptCodeTarget) {
      this.aptCodeTarget.addEventListener(
        "input",
        this.filter_change.bind(this)
      );
    } else {
      console.error("aptCode target not found");
    }

    if (this.hasCountryTarget) {
      this.countryTarget.addEventListener(
        "change",
        this.filter_change.bind(this)
      );
    } else {
      console.error("country target not found");
    }

    if (this.hasGfCategoriesTarget) {
      this.gfCategoriesTarget.addEventListener(
        "change",
        this.filter_change.bind(this)
      );
    } else {
      console.error("gfCategories target not found");
    }
  }

  filter_change() {
    var selectedCity = "";
    var selectedLocationType = "";
    var selectedBuilding = "";
    var selectedFlughafenZone = "";
    var selectedArea = "";
    var selectedFinger = "";
    var selectedLevel = "";
    var selectedAddress = "";
    var selectedPlayerName = "";
    var selectedBoxId = "";
    var selectedAptCode = "";
    var selectedCountry = "";
    var selectedGfCategories = "";

    if (this.hasCityTarget) {
      selectedCity = this.cityTarget?.value || "";
    } else {
      selectedCity;
    }

    if (this.hasLocationTypeTarget) {
      selectedLocationType = this.locationTypeTarget?.value || "";
    } else {
      selectedLocationType;
    }

    if (this.hasBuildingTarget) {
      selectedBuilding = this.buildingTarget?.value || "";
    } else {
      selectedBuilding;
    }

    if (this.hasFlughafenZoneTarget) {
      selectedFlughafenZone = this.flughafenZoneTarget?.value || "";
    } else {
      selectedFlughafenZone;
    }

    if (this.hasAreaTarget) {
      selectedArea = this.areaTarget?.value || "";
    } else {
      selectedArea;
    }

    if (this.hasFingerTarget) {
      selectedFinger = this.fingerTarget?.value || "";
    } else {
      selectedFinger;
    }

    if (this.hasLevelTarget) {
      selectedLevel = this.levelTarget?.value || "";
    } else {
      selectedLevel;
    }

    if (this.hasAddressTarget) {
      selectedAddress = this.addressTarget?.value || "";
    } else {
      selectedAddress;
    }

    if (this.hasPlayerNameTarget) {
      selectedPlayerName = this.playerNameTarget?.value || "";
    } else {
      selectedPlayerName;
    }

    if (this.hasBoxIdTarget) {
      selectedBoxId = this.boxIdTarget?.value || "";
    } else {
      selectedBoxId;
    }

    if (this.hasAptCodeTarget) {
      selectedAptCode = this.aptCodeTarget?.value || "";
    } else {
      selectedAptCode;
    }

    if (this.hasCountryTarget) {
      selectedCountry = this.countryTarget?.value || "";
    } else {
      selectedCountry;
    }

    if (this.hasGfCategoriesTarget) {
      selectedGfCategories =
        this.gfCategoriesTarget?.value.trim().toLowerCase() || "";
    } else {
      selectedGfCategories;
    }

    this.updateRowsWithFilters(
      selectedCity,
      selectedLocationType,
      selectedBuilding,
      selectedFlughafenZone,
      selectedArea,
      selectedFinger,
      selectedLevel,
      selectedAddress,
      selectedPlayerName,
      selectedBoxId,
      selectedAptCode,
      selectedCountry,
      selectedGfCategories
    );
  }

  updateRowsWithFilters(
    city,
    locationType,
    building,
    flughafenZone,
    area,
    finger,
    level,
    address,
    playerName,
    boxId,
    aptCode,
    country,
    gfCategories
  ) {
    const rows = this.element.querySelectorAll("tbody tr");

    const cityRegex = city ? new RegExp(city, "i") : null;
    const buildingRegex = building ? new RegExp(building, "i") : null;
    const flughafenZoneRegex = flughafenZone
      ? new RegExp(flughafenZone, "i")
      : null;
    const areaRegex = area ? new RegExp(area, "i") : null;
    const fingerRegex = finger ? new RegExp(finger, "i") : null;
    const levelRegex = level ? new RegExp(level, "i") : null;
    const addressRegex = address ? new RegExp(address, "i") : null;
    const playerNameRegex = playerName ? new RegExp(playerName, "i") : null;
    const boxIdRegex = boxId ? new RegExp(boxId, "i") : null;
    const countryRegex = country ? new RegExp(country, "i") : null;

    rows.forEach((row) => {
      const rowCity = row.getAttribute("data-city") || "";
      const rowLocationType = row.getAttribute("data-location-type");
      const rowBuilding = row.getAttribute("data-building") || "";
      const rowFlughafenZone = row.getAttribute("data-flughafen-zone") || "";
      const rowArea = row.getAttribute("data-area") || "";
      const rowFinger = row.getAttribute("data-finger") || "";
      const rowLevel = row.getAttribute("data-level") || "";
      const rowAddress = row.getAttribute("data-address") || "";
      const rowPlayerName = row.getAttribute("data-player-name") || "";
      const rowBoxId = row.getAttribute("data-box-id") || "";
      const rowAptCode = row.getAttribute("data-apt-code");
      const rowCountry = row.getAttribute("data-country") || "";
      const rowCategories = (row as HTMLElement).dataset.category || "";
      let rowCategoriesArray = rowCategories
        .split(",")
        .map((cat) => cat.trim().toLowerCase());

      const gfCategoriesMatches =
        !gfCategories || rowCategoriesArray.includes(gfCategories);

      const cityMatches = cityRegex ? cityRegex.test(rowCity) : true;

      const locTypeMatches = !locationType || locationType === rowLocationType;

      const buildingMatches = buildingRegex
        ? buildingRegex.test(rowBuilding)
        : true;

      const flughafenZoneMatches = flughafenZoneRegex
        ? flughafenZoneRegex.test(rowFlughafenZone)
        : true;

      const areaMatches = areaRegex ? areaRegex.test(rowArea) : true;

      const fingerMatches = fingerRegex ? fingerRegex.test(rowFinger) : true;

      const levelMatches = levelRegex ? levelRegex.test(rowLevel) : true;

      const addressMatches = addressRegex
        ? addressRegex.test(rowAddress)
        : true;

      const playerNameMatches = playerNameRegex
        ? playerNameRegex.test(rowPlayerName)
        : true;

      const boxIdMatches = boxIdRegex ? boxIdRegex.test(rowBoxId) : true;

      const aptCodeMatches =
        !aptCode || aptCode === "" || rowAptCode === aptCode;

      const countryMatches = countryRegex
        ? countryRegex.test(rowCountry)
        : true;

      if (
        cityMatches &&
        locTypeMatches &&
        buildingMatches &&
        flughafenZoneMatches &&
        areaMatches &&
        fingerMatches &&
        levelMatches &&
        addressMatches &&
        playerNameMatches &&
        boxIdMatches &&
        aptCodeMatches &&
        countryMatches &&
        gfCategoriesMatches
      ) {
        row.classList.remove("hidden");
      } else {
        row.classList.add("hidden");
      }
    });
  }
}
